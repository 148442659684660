
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import { Component, Vue } from 'vue-property-decorator'
import Expense from '@/models/Expense'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import InvoicePicker from '@/components/InvoicePicker/InvoicePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'

import { currencyMask, percentagDecimaleMask } from '@/models/interface/Masks'
// @ts-ignore
import { VueMaskDirective } from 'v-mask'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'

import WebMessage from '@/models/WebMessage'
import {
  CommisionDeductible,
  CommisionDeductibleMode,
  ExpenseCategories,
} from '@/models/interface/Common'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import MediaPlanPicker from './components/MediaPlanPicker.vue'

Vue.directive('mask', VueMaskDirective)

@Component({
  components: {
    Widget,
    FooterNav,
    FormInput,
    InvoicePicker,
    SelectPicker,
    DatePicker,
    ModelFileUploader,
    InsertionOrderPicker,
    MediaPlanPicker,
    CompanyPicker,
  },
})
export default class ExpensesView extends ViewModel {
  // @ts-ignore
  public expense = new Expense()

  public busy: boolean = true

  public loading: boolean = true

  public filter_search_open: boolean = false

  public get masks() {
    return { currencyMask, percentagDecimaleMask }
  }

  public get _isEditing() {
    return !!this.$route.params.id
  }

  public get pageTitle() {
    return this._isEditing ? 'Edit expense' : 'New Expense'
  }

  public get require_media_plans() {
    return ['specific_media_plans'].includes(this.expense.commission_deduct)
  }

  public get options() {
    return {
      commission_deduct: CommisionDeductible,
      commission_deduct_mode: CommisionDeductibleMode,
      category: ExpenseCategories,
    }
  }

  public loadExpense() {
    Expense.find(this.$route.params.id).then(response => {
      this.expense = response
      this.loading = false
      this.busy = false
      return response.data
    })
  }

  public get checkInfo() {
    return this.expense.media_plan_id !== null || this.expense.media_plan_item_id !== null
  }

  public saveExpenseDetails() {
    if (this.expense.amount === 0) {
      WebMessage.warning('Please add the Expense amount')
    }
    this.loading = true
    if (!this.expense.conflict) {
      this.saveExpense()
      return
    }
    WebMessage.confirm(
      'This expense was updated on Quickbooks and it might have conflicts, are you sure that you want to update the this record?',
      'Update Expense',
      { okTitle: 'Save' },
    ).then((confirmed: boolean) => {
      if (confirmed) {
        this.saveExpense()
      } else {
        this.loading = false
      }
    })
  }

  public saveExpense() {
    this.expense
      .save()
      .then(() => {
        this.$router.push({ name: 'expenses' })
      })
      .catch(() => {
        this.loading = false
      })
  }

  mounted() {
    if (!this._isEditing) {
      this.loading = false
      this.busy = false
    } else {
      this.loadExpense()
    }
  }
}
